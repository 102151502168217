import React from "react";

import Vector1 from "../../assets/image/inner-page/png/benefits/b1.png";
import Vector2 from "../../assets/image/inner-page/png/benefits/b2.png";
import Vector3 from "../../assets/image/inner-page/png/benefits/b3.png";
import Vector4 from "../../assets/image/inner-page/png/benefits/b4.png";
import Vector5 from "../../assets/image/inner-page/png/benefits/b5.png";
import Vector6 from "../../assets/image/inner-page/png/benefits/b6.png";
import Vector7 from "../../assets/image/inner-page/png/benefits/b7.png";
import Vector8 from "../../assets/image/inner-page/png/benefits/b8.png";
import Vector9 from "../../assets/image/inner-page/png/benefits/b9.png";
import Vector10 from "../../assets/image/inner-page/png/benefits/b10.png";
import Vector11 from "../../assets/image/inner-page/png/benefits/b11.png";
import Vector12 from "../../assets/image/inner-page/png/benefits/b12.png";
import Vector13 from "../../assets/image/inner-page/png/benefits/b13.png";
import Vector14 from "../../assets/image/inner-page/png/benefits/b14.png";

const Section2 = () => {
  const array = [
    {
      icon: Vector1,
      text: "Unlock valuable insights by efficiently analyzing data from rich sources.",
    },
    {
      icon: Vector2,
      text: "Maximize efficiency and productivity with automated tools tailored to streamline your operations",
    },
    {
      icon: Vector3,
      text: "Gain valuable insights into stakeholder behavior trends with clear-cut views",
    },
    {
      icon: Vector4,
      text: "Effortlessly scale your educational impact across multiple centers with our deployable solution.",
    },
    {
      icon: Vector5,
      text: "Simplify student performance evaluation with our straightforward and efficient approach.",
    },
    {
      icon: Vector6,
      text: "Unlock seamless, expedited, and secure transactions for enhanced peace of mind.",
    },
    {
      icon: Vector7,
      text: "Empower universal access or personalized privacy with our transformative solution.",
    },
    {
      icon: Vector5,
      text: "Experience unparalleled speed with our cutting-edge student data technology.",
    },
    {
      icon: Vector8,
      text: "Maximize savings and mitigate risks with our cost-effective solution.",
    },
    {
      icon: Vector9,
      text: "Elevate both internal and external communication to new heights with our advanced solution.",
    },
    {
      icon: Vector10,
      text: "Unlock unparalleled integration capabilities with our highly advanced modules.",
    },
    {
      icon: Vector11,
      text: "Boost student success and foster long-term engagement with our proven methods for increasing retention rates.",
    },
    {
      icon: Vector5,
      text: "Effortlessly access comprehensive student master profiles with lightning-fast retrieval capabilities.",
    },
    {
      icon: Vector12,
      text: "Ensure unparalleled compliance throughout your operations with our comprehensive solution.",
    },
    {
      icon: Vector14,
      text: "Enhance operational efficiency with our feature-rich, self-service modules offering a multitude of capabilities.",
    },
    {
      icon: Vector13,
      text: "Optimize the entire educational journey from onboarding to graduation for unparalleled success.",
    },
    {
      icon: Vector13,
      text: "Boost accountability with transparent processes for enhanced institutional integrity.",
    },
    {
      icon: Vector7,
      text: "Track student processes in real-time for instant insights and efficient management.",
    },
  ];

  const Card = (props) => {
    return (
      <div className="col-12 mb-12 col-md-6 d-flex justify-content-center">
        <div
          className={`d-flex bg-default-3  shadow-sm pt-5 justify-content-center align-items-center ${
            props?.id % 2 == 1 ? "right-circle" : "left-circle"
          }`}
        >
          <>
            <img className="mb-3" height={70} width={80} src={props?.icon} />
            <p className="ml-5 w-75   mt-3 font-size-6 light-text line-height-20 font-weight-light">
              {props?.text}
            </p>
          </>
        </div>
      </div>
    );
  };

  return (
    <div className="container pt-20 pb-15">
      {/* Section Padding */}
      <div className="row justify-content-center">
        {array.map((item, index) => (
          <Card key={index} id={index} icon={item?.icon} text={item?.text} />
        ))}
      </div>
      {/* End Section Padding */}
    </div>
  );
};

export default Section2;
