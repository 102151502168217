import React from "react";
import Banner from "../../assets/image/inner-page/png/benefits/integration-banner.png";

const Section3 = () => {
  const Card = (props) => {
    return (
      <div className="col-10 mb-10 pt-7 shadow rounded">
        <p className="mb-5 font-size-4 text-center font-weight-medium">
          {props?.title}
        </p>
        <p className="text-center font-size-3 gray-text">{props?.text}</p>
      </div>
    );
  };

  return (
    <div
      style={{ backgroundColor: "#F2F2F2" }}
      className="px-0 px-lg-30 pt-10 pb-20"
    >
      <div
        className="text-center mb-11"
        data-aos="zoom-in"
        data-aos-duration={500}
        data-aos-delay={500}
      >
        <h2 className="font-size-9 title mb-5">
          We support third party integration
        </h2>
      </div>
      <img src={Banner} className="img-fluid d-none d-md-block" />
      <div className="row justify-content-center d-md-none">
        <Card
          title={"LMS"}
          text={
            "Boost learning outcomes with seamless LMS integration for streamlined course management and interactive engagement."
          }
        />
        <Card
          title={"Payment Gateways"}
          text={
            "Simplify and enhance your payment processes by seamlessly integrating a robust payment gateway."
          }
        />
        <Card
          title={"Data Visualization Tools"}
          text={
            "Unlock insightful data-driven decision-making through seamless integration of powerful data visualization tools."
          }
        />
        <Card
          title={"Finance tool"}
          text={
            "Optimize financial management and drive growth through seamless integration of powerful finance tools."
          }
        />
        <Card
          title={"SMS Gateways"}
          text={
            "Enhance communication reach and efficiency by seamlessly integrating SMS gateway for instant and effective messaging."
          }
        />
      </div>
    </div>
  );
};

export default Section3;
