import React from "react";
import Icon1 from "../../assets/image/inner-page/png/modules/functionalities/icon2.png";
import Icon2 from "../../assets/image/inner-page/png/modules/functionalities/icon8.png";
import Icon3 from "../../assets/image/inner-page/png/modules/functionalities/icon7.png";
import Icon4 from "../../assets/image/inner-page/png/modules/functionalities/icon4.png";
import Icon5 from "../../assets/image/inner-page/png/modules/functionalities/icon5.png";
import Icon6 from "../../assets/image/inner-page/png/modules/functionalities/icon6.png";

const Section1 = () => {
  const Card = (props) => {
    return (
      <div className="col-12  mb-10 col-sm-6 col-lg-4">
        <div className="rounded-15 shadow h-100 bg-default-3">
          <div className="w-100 d-flex justify-content-center">
            <img
              className="mb-5 mt-10"
              height={60}
              width={60}
              src={props?.icon}
            />
          </div>
          <p className="px-12 pb-5 pt-7 text-center line-height-26 font-weight-medium font-size-6 dark-text">
            {props?.text}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      {/* Section Padding */}
      <div className="row justify-content-center">
        <Card icon={Icon1} text={"Empower Your Decision Making Ability"} />
        <Card icon={Icon2} text={"Unleash the Power of Automated Systems"} />
        <Card icon={Icon3} text={"Unlock the Secrets of Time Balance"} />
        <Card icon={Icon4} text={"Elevate Your Transaction Experience"} />
        <Card icon={Icon5} text={"Embrace the Power of Remote Work"} />
        <Card
          icon={Icon6}
          text={"Elevate Your experience with Cloud Services"}
        />
      </div>
      {/* End Section Padding */}
    </div>
  );
};

export default Section1;
