import React, { useEffect } from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/benefits/Hero";
import Section1 from "../sections/benefits/Section1";
import Section2 from "../sections/benefits/Section2";
import Section3 from "../sections/benefits/Section3";

const Benefits = () => {
  useEffect(() => {
    const currentPath = window.location.pathname;
    const withoutSlashesPath = currentPath.replace(/^\/+|\/+$/g, "").trim();
    const newPath = `/${withoutSlashesPath}/index.html`;
    window.history.replaceState({}, "", newPath);
  }, []);

  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          footerStyle: "style3",
        }}
      >
        <Hero />
        <Section1 />
        <Section2 />
        <Section3 />
      </PageWrapper>
    </>
  );
};
export default Benefits;
